export const config = {
  apiUrl: 'https://dominio3d.me',
  appName: 'dominio3d',
  correo: 'dominio3d.me@gmail.com',
  envioGratis: '20',

  tiktok :'',
  instagram: '',

  lineaUno: '',
  lineaDos: 'Envio desde España',
  lineaTres: 'Especialistas en palas',

  theme: {
    background: '#282828', //Navbar
    //Navbar: categorias, hamburguesa, icoBuscar, carrito
    text: 'rgba(255, 255, 255, 0.75)',
    textHover: 'rgba(255, 255, 255, 1)',

    //Icono buscar en el desplegable
    buttonBg: 'rgb(150,150,150)', 
    buttonHover: '#383838',
   
    //Letrero arriba del todo
    colorBackgroundBanner: '#FFFFFF',
    colorTextBanner: '#000000',

    //Boton shopNow del medio
    colorBackgroundShopnow : '#FFFFFF',
    colorTextBorderShopnow : '#000000',

}
};
